import X from 'xregexp';

export const common = X('\\p{Common}');
export const arabic = X('\\p{Arabic}');
export const armenian = X('\\p{Armenian}');
export const bengali = X('\\p{Bengali}');
export const bopomofo = X('\\p{Bopomofo}');
export const braille = X('\\p{Braille}');
export const buhid = X('\\p{Buhid}');
export const canadianAboriginal = X('\\p{Canadian_Aboriginal}');
export const cherokee = X('\\p{Cherokee}');
export const cyrillic = X('\\p{Cyrillic}');
export const devanagari = X('\\p{Devanagari}');
export const ethiopic = X('\\p{Ethiopic}');
export const georgian = X('\\p{Georgian}');
export const greek = X('\\p{Greek}');
export const gujarati = X('\\p{Gujarati}');
export const gurmukhi = X('\\p{Gurmukhi}');
export const han = X('\\p{Han}');
export const hangul = X('\\p{Hangul}');
export const hanunoo = X('\\p{Hanunoo}');
export const hebrew = X('\\p{Hebrew}');
export const hiragana = X('\\p{Hiragana}');
export const inherited = X('\\p{Inherited}');
export const kannada = X('\\p{Kannada}');
export const katakana = X('\\p{Katakana}');
export const khmer = X('\\p{Khmer}');
export const lao = X('\\p{Lao}');
export const latin = X('\\p{Latin}');
export const limbu = X('\\p{Limbu}');
export const malayalam = X('\\p{Malayalam}');
export const mongolian = X('\\p{Mongolian}');
export const myanmar = X('\\p{Myanmar}');
export const ogham = X('\\p{Ogham}');
export const oriya = X('\\p{Oriya}');
export const runic = X('\\p{Runic}');
export const sinhala = X('\\p{Sinhala}');
export const syriac = X('\\p{Syriac}');
export const tagalog = X('\\p{Tagalog}');
export const tagbanwa = X('\\p{Tagbanwa}');
export const taile = X('\\p{TaiLe}');
export const tamil = X('\\p{Tamil}');
export const telugu = X('\\p{Telugu}');
export const thaana = X('\\p{Thaana}');
export const thai = X('\\p{Thai}');
export const tibetan = X('\\p{Tibetan}');
export const yi = X('\\p{Yi}');
